@import url(https://fonts.googleapis.com/css?family=Exo+2);

main {
  font-family: "Exo 2";
}

.logo {
  width: 90%;
  padding-left: 10px;
  margin-left: 1em;
}

.carrusel {
  max-width: 100%;
  height: 80vh;
  width: auto;
  margin: auto;
  display: block;
  object-fit: fill;
}
p,
h1,
h2,
a,
h3 {
  color: black;
}
.carousel-control-prev-icon {
  color: black;
}

.footerLogo {
  max-width: 30%;
}
footer {
  text-align: center;
}

.site-footer {
  background-color: white;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: black;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: black;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.social-icons a:hover {
  background-color: #00aced;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#hero .content {
  padding: 3%;

  animation-duration: 3s;
  animation-name: fadein;
}

#hero p {
  color: rgba(49, 13, 119, 1);
  font-size: 110%;
}
#hero h3 {
  color: rgba(49, 13, 119, 1);
}
#hero h1 {
  color: rgba(49, 13, 119, 1);
}
.mision {
  padding: 3%;
  background: rgba(88, 148, 201, 0.6);
  border-radius: 30px;
  animation-duration: 3s;
  animation-name: fadein;
}

.vision {
  background-color: rgba(100, 78, 155, 0.6);

  padding: 3%;

  border-radius: 30px;
  animation-duration: 3s;
  animation-name: fadein;
}

.pilares {
  background-color: rgba(49, 13, 119, 0.6);

  padding: 3%;

  border-radius: 30px;
  animation-duration: 3s;
  animation-name: fadein;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#hero .content h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4vh;
  margin-bottom: 3%;
}

#hero .content p {
  margin-bottom: 3%;
}

.toLeft {
  width: 70%;
  animation-name: slideleft;
  animation-duration: 2s;
}

@keyframes slideleft {
  from {
    margin-right: 100%;
  }

  to {
    margin-right: 0%;
  }
}

@keyframes slideright {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.sameHeight {
  margin-top: 10px;
  display: flex;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 10px;
}
.sameHeight h2 {
  text-align: center;
  color: white;
}
.sameHeight p {
  margin: 5%;
  color: white;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.aboutPhoto {
  max-width: 60%;
  margin-left: 20%;
  margin-bottom: 10vh;
}

.downloads {
  margin: 10px;
  background-color: rgba(100, 78, 155);
  border: none;
}
.downloads:hover {
  background-color: rgba(88, 148, 201, 0.6);
}
.cinta {
  top: 0;
  width: 25%;
  height: 100%;
  right: 0;
  bottom: 0;
  float: right;
  margin-right: -14%;
  z-index: 1;
  position: absolute;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.header {
  background-color: rgba(49, 13, 119, 1);
}

.whiteHeader {
  color: white;
}
